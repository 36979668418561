import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Import Modal Component
import Modal from './components/Modal.vue'
import NavbarCollapse from './components/NavbarCollapse.vue';

// Import Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'

// Import Theme
import '@/assets/theme/src/scss/theme.scss'

// Import FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('ModalComponent', Modal);
app.component('NavbarCollapseComponent', NavbarCollapse);
app.use(store).use(router).mount('#app');