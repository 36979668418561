<template>
  <button hidden :id="`${id}_modal_button`" type="button" data-bs-toggle="modal" :data-bs-target="`#${id}`">

  </button>
  <div class="modal fade rounded-lg" :id="id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="size">
      <div class="modal-content rounded-lg">
        <div class="modal-header d-flex justify-content-between align-items-start border-0">
          <span>
            <h3 class="fw-bold text-secondary">{{ title }}</h3>
          <p class="text-secondary mb-0">{{ description }}</p>
          </span>
          <font-awesome-icon :icon="['fas', 'xmark']" size="xl" class="fw-bold text-primary" v-if="hasCloseButton" data-bs-dismiss="modal" aria-label="Close" type="button" @click="modalClosed" />
        </div>
        <div class="modal-body border-0 pt-0">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer border-0" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: String,
    title: String,
    description: String,
    size: String,
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    modalClosed() {
      this.$emit("modalClosed");
    },
  },
});

export function toggleModal(id: string) {
  const button = document.getElementById(`${id}_modal_button`)
  if (button) {
    button?.click()
  }
}

</script>

<style>

</style>
