import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "data-bs-target"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "modal-content rounded-lg" }
const _hoisted_4 = { class: "modal-header d-flex justify-content-between align-items-start border-0" }
const _hoisted_5 = { class: "fw-bold text-secondary" }
const _hoisted_6 = { class: "text-secondary mb-0" }
const _hoisted_7 = { class: "modal-body border-0 pt-0" }
const _hoisted_8 = {
  key: 0,
  class: "modal-footer border-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      hidden: "",
      id: `${_ctx.id}_modal_button`,
      type: "button",
      "data-bs-toggle": "modal",
      "data-bs-target": `#${_ctx.id}`
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "modal fade rounded-lg",
      id: _ctx.id,
      tabindex: "-1",
      "aria-labelledby": "exampleModalLabel",
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog modal-dialog-centered modal-dialog-scrollable", _ctx.size])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.title), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
            ]),
            (_ctx.hasCloseButton)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: ['fas', 'xmark'],
                  size: "xl",
                  class: "fw-bold text-primary",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close",
                  type: "button",
                  onClick: _ctx.modalClosed
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "body")
          ]),
          (_ctx.$slots.footer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 8, _hoisted_2)
  ], 64))
}